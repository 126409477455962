import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps, Link } from 'react-router-dom';
import { Globals, Constants, Colors, Print, checkPermission } from 'utils';
import { Table, Pagination, Tooltip, Input, DatePicker, Select, Modal } from 'components';
import { CleanIcon, EditIcon, TrashIcon, AddIcon, DeleteIcon, ViewIcon } from 'assets/icons';
import { ReminderService } from 'services';
import ModalCreate from './modal-create';
import moment from 'moment';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

const INITIAL_STATE = {
	search: '',
	channel: '',
	type_id: ''
}

class Reminders extends React.Component<Props> {

	timer: any = undefined;

	state = {
		header: [
			'Destinatarios',
			'Mensaje',
			'Canal',
			'Días',
			'Hora',
			// 'Sanes',
			'Acciones'
		],
		form: INITIAL_STATE,
		data: [],
		page: 1,
		last_page: 1,
		visible: false,
		item: null,
		types: [],
		visible_sanes: false
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Recordatorios',
				onBack: null
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: ''
			}
		});

		this.load();
		this.loadTypes();
	}

	loadTypes = async () => {
		const res: any = await ReminderService.admin.getTypes({
			withoutLoading: true
		});
		this.setState({
			types: res.types || []
		});	
	}

	load = async (withoutLoading: boolean = false,callback = () => {}) => {
		const res: any = await ReminderService.admin.get({
			withoutLoading,
			page: this.state.page,
			...this.state.form,
		});
		this.setState({
			data: res.reminders.rows,
			last_page: res.reminders.count
		});
	}

	add = () => {
		this.setState({
			visible: true
		});
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	reset = () => {
		this.setState({
			form: INITIAL_STATE,
			page: 1
		},() => this.load(true));
	}

	edit = (e: any, item: any) => {
		e.preventDefault();
		this.setState({
			visible: true,
			item
		});
	}

	onClose = () => {
		this.setState({
			visible: false,
			item: null
		},() => {
			this.load(true);
		});
	}

	convertToDays = (days: string) => {
		days = JSON.parse(days);

		let _days = [];

		for (let i = 0; i < days.length; i++) {
			// @ts-ignore
			_days.push(Globals.convertDay(days[i]));
		}

		return _days.join(', ');
	}

	delete = (e: any, item: any) => {
		e.preventDefault();
		Globals.confirm('¿Desea eliminar el recordatorio?',async () => {
			await ReminderService.admin.delete({
				reminder_id: item?.id
			});
			Globals.showSuccess("Se ha eliminado el recordatorio correctamente");
			this.load(true);
		});
	}

	view = (e: any, item: any) => {
		e.preventDefault();
		this.setState({
			visible_sanes: true,
			item
		});
	}

	onCloseSanes = () => {
		this.setState({
			visible_sanes: false,
			item: null
		});
	}
	
	render() {
		const { visible, visible_sanes } = this.state;
		const item: any = this.state.item;

		return (
			<div id="san-structures">
				<Modal
		          className="modal-create-reminder"
		          visible={ visible }
		          title={ `${ this.state.item != null ? 'Editar' : 'Creación de' } Recordatorio` }
		          onClose={ () => this.onClose() }
		        >
		          <ModalCreate
		          	item={ this.state.item }
		            onClose={ () => this.onClose() } />
		      	</Modal>

		      	<Modal
		          className="modal-view-sanes-notification"
		          visible={ visible_sanes }
		          title="Recordatorio"
		          onClose={ () => this.onCloseSanes() }
		        >
		          <div id="modal-view-sanes-notification">
			          <Table title="" data={ item?.sanes?.length } header={ [] }>
						{ item?.sanes?.map((i: any,index: number) => {
							return (
								<tr key={ index }>
									<td>{ i.san?.code }</td>
								</tr>
							)
						}) }
					  </Table>
				  </div>
		      	</Modal>

				<div className="row row-filter">
					<div className="col-md-12">
						<div className="row">
							<div className="row col-md-11">
								<div className="col-md-4">
									<Input
										value={ this.state.form.search }
										name="search"
										placeholder="Buscar"
										label="Mensaje"
										className="input-table"
										onSubmit={ () => this.load() }
										onChange={ (e: any) => {
											clearTimeout(this.timer);
											this.timer = setTimeout(() => {
												this.load(true);
											},1000);
											this.change(e); 
										} } />
								</div>
								<div className="col-md-4">
									<Select
										placeholder="Seleccionar"
										label="Destinatarios"
										name="type_id"
										onChange={ (e: any) => this.change(e,async () => {
											this.load(true);
										}) }
										value={ this.state.form.type_id }
										options={ this.state.types.map((item: any) => {
											return {
												value: item.id,
												label: item.name
											}
										}) } />
								</div>
								<div className="col-md-4">
									<Select
										placeholder="Seleccionar"
										label="Canal"
										name="channel"
										onChange={ (e: any) => this.change(e,async () => {
											this.load(true);
										}) }
										value={ this.state.form.channel }
										options={ [
											{ value: Constants.REMINDER_CHANNELS.SMS.toString(), label: 'SMS' },
											{ value: Constants.REMINDER_CHANNELS.PUSH.toString(), label: 'Push' },
											{ value: Constants.REMINDER_CHANNELS.BOTH.toString(), label: 'Ambos' }
										] } />
								</div>
							</div>
							<div className="col-md-1">
								{
									this.state.form != INITIAL_STATE && (
										<button className="btn btn-clean" onClick={ () => this.reset() }>
											<img src={ CleanIcon } />
										</button>
									)
								}
							</div>
						</div>
					</div>
				</div>

				<Table title="Recordatorios" data={ this.state.data.length } header={ this.state.header } titleRight={
					<>
						{
							checkPermission(Constants.MODULES.REMINDERS,'write') && (
								<div className="container-add-table">
									<p>Nuevo</p>
									<button className="btn add-btn-table" onClick={ this.add }>
										<img src={ AddIcon } />
									</button>
								</div>
							)
						}
					</>
				}>
					{ this.state.data.map((i: any,index: number) => {
						return (
							<tr key={ index }>
								<td>{ i.type?.name || '-' }</td>
								<td>{ i.message }</td>
								<td>{ i.channel_text }</td>
								<td>{ i.mode == Constants.REMINDER_MODES.DATE ? (moment(i.date).isValid() && moment(i.date).format('DD/MM/YYYY') || '') : this.convertToDays(i.days) }</td>
								<td>{ moment(i.hour,'HH:mm:ss').format('hh:mm A') }</td>
								{/*<td>
									{
										i.sanes.length > 0 ? (
											<Tooltip title="Ver">
												<a className="link-icon" href="#" onClick={ (e: any) => this.view(e,i) }>
													<img src={ ViewIcon } />
												</a>
											</Tooltip>
										) : (
											<>-</>
										)
									}
								</td>*/}
								<td style={{ 'whiteSpace': 'nowrap' }}>
									{
										checkPermission(Constants.MODULES.REMINDERS,'write') && (
											<Tooltip title="Editar">
												<a className="link-icon" href="#" onClick={ (e: any) => this.edit(e,i) }>
													<img src={ EditIcon } />
												</a>
											</Tooltip>
										)
									}
									{
										checkPermission(Constants.MODULES.REMINDERS,'delete') && (
											<Tooltip title="Eliminar">
												<a className="link-icon link-red" href="#" onClick={ (e: any) => this.delete(e,i) }>
													<img src={ TrashIcon } />
												</a>
											</Tooltip>
										)
									}
								</td>
							</tr>
						)
					}) }
				</Table>

				<Pagination 
					pages={ this.state.last_page } 
					active={ this.state.page }
					onChange={ async (page: number) => {
						await this.setState({
							page: page
						});
						this.load();
					} } />
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(Reminders);