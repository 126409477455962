import { Api } from 'utils';

class GlobalNotificationService {
		
	static admin = {
		get: async (params?: any) => Api.createResponse('admin/global-notifications/',params),
		send: async (params?: any) => Api.createResponse('admin/global-notifications/send',params),
		getTypes: async (params?: any) => Api.createResponse('admin/global-notifications/types',params),
		getReminderTexts: async (params?: any) => Api.createResponse('admin/global-notifications/reminder-texts',params),
		saveReminderTexts: async (params?: any) => Api.createResponse('admin/global-notifications/save-reminder-texts',params),
		getSanes: async (params?: any) => Api.createResponse('admin/global-notifications/sanes',params),
	}

}

export default GlobalNotificationService;