import { Api } from 'utils';

class ReportService {
		
	static admin = {
		getPayments: async (params?: any) => Api.createResponse('admin/reports/payments',params),
		downloadPayments: async (params?: any) => Api.createResponse('admin/reports/payments/download',params),
		getPoints: async (params?: any) => Api.createResponse('admin/reports/points',params),
		downloadPoints: async (params?: any) => Api.createResponse('admin/reports/points/download',params),
		getSettings: async (params?: any) => Api.createResponse('admin/reports/points/settings',params),
		saveSettings: async (params?: any) => Api.createResponse('admin/reports/points/save-settings',params),
		getExcelReport: async (params?: any) => Api.createResponse('admin/reports/excel-report',params),
	}

}

export default ReportService;