import React from 'react';
import { Input, Button, Select, Textarea, SelectSearch } from 'components';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Globals, Constants } from 'utils';
import { GlobalNotificationService } from 'services';
import { CheckWhiteIcon, CloseIcon } from 'assets/icons';

interface Props {
	onClose?: any;
	types?: any;
}

class SendGlobalNotification extends React.Component<Props> {

	state = {
		form: {
			message: '',
			type_id: '',
			way: '',
			include_alt_phone: Constants.INCLUDE_ALT_PHONE.NO,
		},
		sanes_id: [],
		sanes: []
	}

	componentDidMount() {
		this.loadSanes();
	}

	loadSanes = async () => {
		const res: any = await GlobalNotificationService.admin.getSanes();
		this.setState({
			sanes: res.sanes || []
		});
	}

	change = (e: any) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		});
	}

	toggleIncludeAltPhone = async () => {
		this.setState({
			form: {
				...this.state.form,
				include_alt_phone: this.state.form.include_alt_phone == Constants.INCLUDE_ALT_PHONE.YES
					? Constants.INCLUDE_ALT_PHONE.NO
					: Constants.INCLUDE_ALT_PHONE.YES
			}
		});
	}
	
	submit = async () => {
		await GlobalNotificationService.admin.send({
			...this.state.form,
			// @ts-ignore
			type_id: parseInt(this.state.form.type_id),
			way: parseInt(this.state.form.way),
			sanes_id: JSON.stringify(this.state.sanes_id)
		});
		this.props.onClose();
		Globals.showSuccess("Se ha enviado la notificación correctamente");
	}

	render() {
		const isSMS = [Constants.REMINDER_CHANNELS.SMS.toString(), Constants.REMINDER_CHANNELS.BOTH.toString()].includes(this.state.form.way);
		const is_sanes_active = [Constants.GLOBAL_NOTIFICATION_TYPES.DUE_1,Constants.GLOBAL_NOTIFICATION_TYPES.DUE_2,Constants.GLOBAL_NOTIFICATION_TYPES.SAN].indexOf(parseInt(this.state.form.type_id)) !== -1;
		let options = [
			{ value: Constants.GLOBAL_NOTIFICATION_WAYS.PUSH.toString(), label: 'Notificación Push' },
			{ value: Constants.GLOBAL_NOTIFICATION_WAYS.SMS.toString(), label: 'SMS' },
			{ value: Constants.GLOBAL_NOTIFICATION_WAYS.BOTH.toString(), label: 'Ambos (Push y SMS)' }
		];

		if (is_sanes_active) {
			options.push({ value: Constants.GLOBAL_NOTIFICATION_WAYS.CHAT.toString(), label: 'Chat Interno' });
		}

		return (
			<div id="send-global-notification">
				<Select
					placeholder="Seleccionar"
					label="Tipo de Notificación"
					name="type_id"
					onChange={ this.change }
					value={ this.state.form.type_id }
					options={ this.props.types.map((item: any) => {
						return {
							value: item.id,
							label: item.name
						}
					}) } />
				<Select
					placeholder="Seleccionar"
					label="Canal"
					name="way"
					onChange={ this.change }
					value={ this.state.form.way }
					options={ options } />

				{/*{
					is_sanes_active && (
						<>
							<SelectSearch
								placeholder="Seleccione"
								label="Seleccionar Sanes (Opcional)"
								onChange={ (san: any) => {
									let sanes_id: any = [...this.state.sanes_id];
									sanes_id.push(san?.value);
									this.setState({
										sanes_id
									});
								} }
								color="white"
								searchRight
								value={ null }
								options={ this.state.sanes.filter((i: any) => {
									// @ts-ignore
									return this.state.sanes_id.indexOf(i.id) == -1;
								}).map((item: any) => {
									return {
										label: item.code,
										value: item.id
									}
								}) } />
							{
								this.state.sanes_id.length > 0 && (
									<div className="container-sanes">
										{
											this.state.sanes_id.map((item: any,index: number) => {
												const san: any = this.state.sanes.find((i: any) => i.id == item);

												return (
													<div className="item-san">
														<p>{ san?.code }</p>
														<img src={ CloseIcon } onClick={ () => {
															let sanes_id: any = [...this.state.sanes_id];
															sanes_id.splice(index,1);
															this.setState({
																sanes_id
															});
														} } />
													</div>
												)
											})
										}
									</div>
								)
							}
						</>
					)
				}*/}
				
				<Textarea
					rows={ 2 }
					value={ this.state.form.message }
					placeholder="Escribir"
					label="Mensaje"
					name="message"
					onChange={ (e: any) => this.change(e) } />

					{
						isSMS && (
							<div className="col-md-12">
								<div className="row row-modes">
									<div className="col-md-6">
										<div className="check-container" onClick={ this.toggleIncludeAltPhone }>
											<div className={ `check ${ this.state.form.include_alt_phone == Constants.INCLUDE_ALT_PHONE.YES ? 'active' : '' }` }>
												{
													this.state.form.include_alt_phone == Constants.INCLUDE_ALT_PHONE.YES && (
														<img src={ CheckWhiteIcon } />
													)
												}
											</div>
											<p>Incluir número secundario</p>
										</div>
									</div>
								</div>
							</div>
						)
					}

				<div className="text-center">
					<button className="btn btn-edit" onClick={ () => this.submit() }>
						Guardar
					</button>
				</div>
			</div>
		)
	}
}

export default SendGlobalNotification;