import React from 'react';
import { Input, Button, Select, Textarea, PaperClip, DatePicker, SelectSearch } from 'components';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Globals, Constants } from 'utils';
import { ReminderService, GlobalNotificationService } from 'services';
import moment from 'moment';
import { CheckWhiteIcon, CloseIcon } from 'assets/icons';

interface Props {
	onClose?: any;
	item?: any;
}

const DAYS = [
	{ value: 1, label: 'Lunes' },
	{ value: 2, label: 'Martes' },
	{ value: 3, label: 'Miércoles' },
	{ value: 4, label: 'Jueves' },
	{ value: 5, label: 'Viernes' },
	{ value: 6, label: 'Sábado' },
	{ value: 7, label: 'Domingo' },
]

class ModalCreate extends React.Component<Props> {

	state = {
		form: {
			type_id: '',
			message: '',
			image: '',
			mode: '',
			hour: '',
			date: '',
			accredited: Constants.REMINDER_ACCREDITED.ALL,
			include_alt_phone: Constants.INCLUDE_ALT_PHONE.NO,
		},
		types: [],
		channels: [
			{ id: Constants.REMINDER_CHANNELS.PUSH, name: 'Push', active: false },
			{ id: Constants.REMINDER_CHANNELS.SMS, name: 'SMS', active: false },
			{ id: Constants.REMINDER_CHANNELS.CHAT, name: 'Chat Interno', active: false },
		],
		days: JSON.parse(JSON.stringify(DAYS)),
		sanes_id: [],
		sanes: []
	}

	componentDidMount() {
		this.load();
		this.loadSanes();

		if (this.props.item) {
			const item: any = this.props.item;
			const days = JSON.parse(item.days);

			let channels: any = [...this.state.channels];

			for (let i = 0; i < channels.length; i++) {
				if (item.channel == channels[i].id || (item.channel == Constants.REMINDER_CHANNELS.BOTH && (channels[i].id == Constants.REMINDER_CHANNELS.PUSH || channels[i].id == Constants.REMINDER_CHANNELS.SMS))) {
					channels[i].active = true;
				}
			}

			this.setState({
				form: {
					...this.state.form,
					type_id: item?.type_id || '',
					message: item?.message || '',
					image: '',
					mode: item?.mode || '',
					hour: item?.hour && moment(item.hour,'HH:mm:ss').toDate() || '',
					date: item?.date && moment(item.date).isValid() && moment(item.date).toDate() || '',
					accredited: item?.accredited || Constants.REMINDER_ACCREDITED.ALL,
					include_alt_phone: item?.include_alt_phone || Constants.INCLUDE_ALT_PHONE.NO,
				},
				days: this.state.days.map((i: any) => {
					if (days.indexOf(i.value) != -1) {
						i.active = true;
					}
					return i;
				}),
				// sanes_id: item?.sanes?.map((i: any) => i.san_id) || [],
				channels
			});
		}
	}

	loadSanes = async () => {
		const res: any = await GlobalNotificationService.admin.getSanes({
			withoutLoading: true
		});
		this.setState({
			sanes: res.sanes || []
		});
	}

	load = async () => {
		const res: any = await ReminderService.admin.getTypes();
		this.setState({
			types: res.types || []
		});	
	}

	submit = async (e: any) => {
		e.preventDefault();

		const push = this.state.channels.find((i: any) => i.id == Constants.REMINDER_CHANNELS.PUSH)?.active;
		const sms = this.state.channels.find((i: any) => i.id == Constants.REMINDER_CHANNELS.SMS)?.active;
		const chat = this.state.channels.find((i: any) => i.id == Constants.REMINDER_CHANNELS.CHAT)?.active;

		let channel: any = '';
		if (push && sms) {
			channel = Constants.REMINDER_CHANNELS.BOTH;
		}
		else if (push) {
			channel = Constants.REMINDER_CHANNELS.PUSH;
		}
		else if (sms) {
			channel = Constants.REMINDER_CHANNELS.SMS;
		}
		else if (chat) {
			channel = Constants.REMINDER_CHANNELS.CHAT;
		}
		else {
			Globals.showError("Debe seleccionar al menos 1 canal");
			return;
		}

		if (this.state.days.filter((i: any) => i.active).length == 0 && parseInt(this.state.form.mode) == Constants.REMINDER_MODES.DAYS) {
			Globals.showError("Debe seleccionar al menos 1 día");
			return;
		}

		if (parseInt(this.state.form.mode) == Constants.REMINDER_MODES.DATE && !this.state.form.date) {
			Globals.showError("El campo fecha es requerido");
			return;
		}

		if (!this.state.form.mode) {
			Globals.showError("Debe seleccionar en que fecha o días se enviara");
			return;
		}

		if (this.props.item) {
			await ReminderService.admin.update({
				...this.state.form,
				reminder_id: this.props.item?.id,
				days: JSON.stringify(this.state.days.filter((i: any) => i.active).map((i: any) => i.value)),
				date: this.state.form.date && moment(this.state.form.date).format('YYYY-MM-DD') || '',
				hour: this.state.form.hour && moment(this.state.form.hour).format('HH:mm:ss') || '',
				channel,
				// @ts-ignore,
				image: this.state.form.image?.value || '',
				hasFile: true,
				sanes_id: JSON.stringify(this.state.sanes_id)
			});
			this.props.onClose();
			Globals.showSuccess("Se ha modificado el recordatorio correctamente");
		}
		else {
			await ReminderService.admin.create({
				...this.state.form,
				days: JSON.stringify(this.state.days.filter((i: any) => i.active).map((i: any) => i.value)),
				date: this.state.form.date && moment(this.state.form.date).format('YYYY-MM-DD') || '',
				hour: this.state.form.hour && moment(this.state.form.hour).format('HH:mm:ss') || '',
				channel,
				// @ts-ignore,
				image: this.state.form.image?.value || '',
				hasFile: true,
				sanes_id: JSON.stringify(this.state.sanes_id)
			});
			this.props.onClose();
			Globals.showSuccess("Se ha registrado el recordatorio correctamente");
		}
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	setCheck = (index: number) => {
		let channels = [...this.state.channels];
		channels[index].active = !channels[index].active;
		this.setState({
			channels
		});
	}

	setMode = async (mode: number) => {
		// @ts-ignore
		if (mode != this.state.form.mode) {
			await this.setState({
				days: JSON.parse(JSON.stringify(DAYS)),
				form: {
					...this.state.form,
					date: ''
				}
			});
		}

		this.setState({
			form: {
				...this.state.form,
				mode
			}
		});
	}

	toggleIncludeAltPhone = async () => {
		this.setState({
			form: {
				...this.state.form,
				include_alt_phone: this.state.form.include_alt_phone == Constants.INCLUDE_ALT_PHONE.YES
					? Constants.INCLUDE_ALT_PHONE.NO
					: Constants.INCLUDE_ALT_PHONE.YES
			}
		});
	}

	setAccredited = async (accredited: number) => {
		this.setState({
			form: {
				...this.state.form,
				accredited
			}
		});
	}
	
	setActiveDay = (index: number) => {
		let days: any = [...this.state.days];
		days[index].active = !days[index].active;
		this.setState({
			days
		});
	}

	render() {
		const item: any = this.props.item;
		const push = this.state.channels.find((i: any) => i.id == Constants.REMINDER_CHANNELS.PUSH);
		const sms = this.state.channels.find((i: any) => i.id == Constants.REMINDER_CHANNELS.SMS);
		const is_sanes_active = [Constants.REMINDER_TYPES.DUE_1,Constants.REMINDER_TYPES.DUE_2,Constants.REMINDER_TYPES.USERS_WITH_SAN].indexOf(parseInt(this.state.form.type_id)) !== -1;

		return (
			<div id="modal-create-reminder">
				<form onSubmit={ this.submit } className="row">
					<div className="col-md-12">
						<p className="bold">Canal</p>
						<div className="row">
							{
								this.state.channels.map((item, index) => {
									return (
										<div className="col-md-4">
											{
												(is_sanes_active || item.id != Constants.REMINDER_CHANNELS.CHAT) && (
													<div className="check-container" onClick={ () => this.setCheck(index) }>
														<div className={ `check ${ item.active ? 'active' : '' }` }>
															{
																item.active && (
																	<img src={ CheckWhiteIcon } />
																)
															}
														</div>
														<p>{ item.name }</p>
													</div>
												)
											}
										</div>
									)
								})
							}
						</div>
					</div>
					<div className="col-md-12">
						<Select
							placeholder="Seleccionar"
							label="Destinatarios"
							name="type_id"
							onChange={ this.change }
							value={ this.state.form.type_id }
							options={ this.state.types.map((item: any) => {
								return {
									value: item.id,
									label: item.name
								}
							}) } />
					</div>
					<div className="col-md-12">
						<Textarea
							rows={ 2 }
							value={ this.state.form.message }
							placeholder="Escribir"
							label="Mensaje"
							name="message"
							onChange={ (e: any) => this.change(e) } />
					</div>

					{/*{
						is_sanes_active && (
							<div className="col-md-12">
								<SelectSearch
									placeholder="Seleccione"
									label="Seleccionar Sanes (Opcional)"
									onChange={ (san: any) => {
										let sanes_id: any = [...this.state.sanes_id];
										sanes_id.push(san?.value);
										this.setState({
											sanes_id
										});
									} }
									color="white"
									searchRight
									value={ null }
									options={ this.state.sanes.filter((i: any) => {
										// @ts-ignore
										return this.state.sanes_id.indexOf(i.id) == -1;
									}).map((item: any) => {
										return {
											label: item.code,
											value: item.id
										}
									}) } />
								{
									this.state.sanes_id.length > 0 && (
										<div className="container-sanes">
											{
												this.state.sanes_id.map((item: any,index: number) => {
													const san: any = this.state.sanes.find((i: any) => i.id == item);

													return (
														<div className="item-san">
															<p>{ san?.code }</p>
															<img src={ CloseIcon } onClick={ () => {
																let sanes_id: any = [...this.state.sanes_id];
																sanes_id.splice(index,1);
																this.setState({
																	sanes_id
																});
															} } />
														</div>
													)
												})
											}
										</div>
									)
								}
							</div>
						)
					}*/}

					{
						sms?.active && (
							<div className="col-md-12">
								<div className="row row-modes">
									<div className="col-md-6">
										<div className="check-container" onClick={ this.toggleIncludeAltPhone }>
											<div className={ `check ${ this.state.form.include_alt_phone == Constants.INCLUDE_ALT_PHONE.YES ? 'active' : '' }` }>
												{
													this.state.form.include_alt_phone == Constants.INCLUDE_ALT_PHONE.YES && (
														<img src={ CheckWhiteIcon } />
													)
												}
											</div>
											<p>Incluir número secundario</p>
										</div>
									</div>
								</div>
							</div>
						)
					}

					<div className="col-md-12">
						<div className="row row-modes">
							<div className="col-md-4">
								<div className="check-container" onClick={ () => this.setAccredited(Constants.REMINDER_ACCREDITED.ALL) }>
									<div className={ `check ${ this.state.form.accredited == Constants.REMINDER_ACCREDITED.ALL ? 'active' : '' }` }>
										{
											this.state.form.accredited == Constants.REMINDER_ACCREDITED.ALL && (
												<img src={ CheckWhiteIcon } />
											)
										}
									</div>
									<p>Todos los usuarios</p>
								</div>
							</div>
							<div className="col-md-4">
								<div className="check-container" onClick={ () => this.setAccredited(Constants.REMINDER_ACCREDITED.YES) }>
									<div className={ `check ${ this.state.form.accredited == Constants.REMINDER_ACCREDITED.YES ? 'active' : '' }` }>
										{
											this.state.form.accredited == Constants.REMINDER_ACCREDITED.YES && (
												<img src={ CheckWhiteIcon } />
											)
										}
									</div>
									<p>Usuarios acreditados</p>
								</div>
							</div>
							<div className="col-md-4">
								<div className="check-container" onClick={ () => this.setAccredited(Constants.REMINDER_ACCREDITED.NO) }>
									<div className={ `check ${ this.state.form.accredited == Constants.REMINDER_ACCREDITED.NO ? 'active' : '' }` }>
										{
											this.state.form.accredited == Constants.REMINDER_ACCREDITED.NO && (
												<img src={ CheckWhiteIcon } />
											)
										}
									</div>
									<p>Usuarios sin acreditar</p>
								</div>
							</div>
						</div>
					</div>

					{
						push?.active && (
							<>
								<div className="col-md-9">
									<PaperClip
										successText="Imagen adjuntada"
										pendingText="Adjuntar imagen"
										value={ this.state.form.image }
										onChange={ (e: any) => {
											this.setState({
												form: {
													...this.state.form,
													image: e.target
												}
											})
										} } />
								</div>
								<div className="col-md-3">
									{
										item?.image != null && (
											<img src={ Constants.BASE_STORAGE + item?.image } className="image-sended" />
										)
									}
								</div>
							</>
						)
					}
					<div className="col-md-12">
						<div className="row row-modes">
							<div className="col-md-6">
								<div className="check-container" onClick={ () => this.setMode(Constants.REMINDER_MODES.DAYS) }>
									<div className={ `check ${ parseInt(this.state.form.mode) == Constants.REMINDER_MODES.DAYS ? 'active' : '' }` }>
										{
											parseInt(this.state.form.mode) == Constants.REMINDER_MODES.DAYS && (
												<img src={ CheckWhiteIcon } />
											)
										}
									</div>
									<p>Enviar los días seleccionados</p>
								</div>
							</div>
							<div className="col-md-6">
								<div className="check-container" onClick={ () => this.setMode(Constants.REMINDER_MODES.DATE) }>
									<div className={ `check ${ parseInt(this.state.form.mode) == Constants.REMINDER_MODES.DATE ? 'active' : '' }` }>
										{
											parseInt(this.state.form.mode) == Constants.REMINDER_MODES.DATE && (
												<img src={ CheckWhiteIcon } />
											)
										}
									</div>
									<p>Enviar un día especifico</p>
								</div>
							</div>
						</div>
						{
							parseInt(this.state.form.mode) == Constants.REMINDER_MODES.DAYS && (
								<div className="container-days">
									{
										this.state.days.map((item: any,index: number) => {
											return (
												<div className={ `item-day ${ item.active ? 'active' : '' }` } onClick={ () => this.setActiveDay(index) }>
													<p>{ item.label }</p>
												</div>
											)
										})
									}
								</div>
							)
						}
					</div>
					{
						parseInt(this.state.form.mode) == Constants.REMINDER_MODES.DATE && (
							<div className="col-md-6">
								<DatePicker
									minDate={ moment().add(1,'day').toDate() }
									label="Fecha"
									placeholder="Fecha"
									onChange={ (text: string) => {
										this.change({
											target: {
												value: text,
												name: 'date'
											}
										});
									} }
									value={ this.state.form.date }
								/>
							</div>
						)
					}
					<div className="col-md-6">
						<DatePicker
							label="Hora"
							placeholder="Hora"
							showTimeSelect={ true }
							showTimeSelectOnly={ true }
							timeCaption="Hora"
							dateFormat="hh:mm a"
							timeFormat="hh:mm a"
							onChange={ (text: string) => {
								this.change({
									target: {
										value: text,
										name: 'hour'
									}
								});
							} }
							value={ this.state.form.hour }
						/>
					</div>
					<div className="col-md-12">
		      			<div className="text-center">
							<Button type="submit">
								Guardar
							</Button>
						</div>
					</div>
				</form>
			</div>
		)
	}
}

export default ModalCreate;